import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import config from '../../config';
import { SAdminList } from '../../constants';
import UserProfile from '../auth/UserProfile';
import {
    FormControlLabel,
    AppBar, Tabs, Tab, TextField, Container, Box, Typography, Card, CardContent, Grid,
    Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch, CircularProgress,
} from '@material-ui/core';

import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';
import { GET_SETTINGS_REFERENCE_DATA, ADD_SETTING, DELETE_SETTING } from '../../libs/AdminQuery';
import { refreshAppCerts } from '../../Helper'
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    card: {
        minHeight: '200px',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        width: '100%',
        maxWidth: '100%',
    },
    supplierSummary: {
        marginTop: theme.spacing(1),
    },
    importantLabel: {
        fontWeight: 'bold',
        display: 'block',
        marginBottom: theme.spacing(1),
    },
}));

const ReferenceDataPresenter = () => {


    const [settingType, setSettingType] = useState('AllowedApps');
    const [settingType2, setSettingType2] = useState('MandatorySerialPhoto');
    const [mode, setMode] = useState(null);
    const { loading, error, data: settingData } = useQuery(GET_SETTINGS_REFERENCE_DATA, {
        variables: { settingType, settingType2 },
    });

    const [addSetting] = useMutation(ADD_SETTING, {
        refetchQueries: [{ query: GET_SETTINGS_REFERENCE_DATA, variables: { settingType, settingType2 } }],
    });

    const [deleteSetting] = useMutation(DELETE_SETTING, {
        refetchQueries: [{ query: GET_SETTINGS_REFERENCE_DATA, variables: { settingType, settingType2 } }],
    });

    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [data, setData] = useState({ manufacturers: [], endpoints: [], apps: [] });
    const [filteredData, setFilteredData] = useState({ manufacturers: [], endpoints: [], apps: [] });
    const [search, setSearch] = useState({ manufacturers: '', endpoints: '', apps: '' });
    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false);
    const [selectedManufacturer, setSelectedManufacturer] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [currentApp, setCurrentApp] = useState(null);
    const [action, setAction] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${config.VSP_URL}/api/endpoint`);
                const result = await response.json();
                setData(result);
                setFilteredData(result);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };
        fetchData();
    }, []);

    const handleSearch = (e, key) => {
        const value = e.target.value.toLowerCase();
        setSearch({ ...search, [key]: value });

        const filtered = data[key].filter(item => {
            if (key === 'manufacturers') {
                return item.name.toLowerCase().includes(value) || item.suppliers.some(supplier => supplier.name.toLowerCase().includes(value));
            } else {
                return (item.serviceadministrator && item.serviceadministrator.toLowerCase().includes(value)) ||
                    (item.appname && item.appname.toLowerCase().includes(value));
            }
        });
        setFilteredData({ ...filteredData, [key]: filtered });
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClickOpen = (manufacturer) => {
        setSelectedManufacturer(manufacturer);
        setOpen(true);
    };
    const handleToggle = (app, action, type) => {
        setMode(type);
        setCurrentApp(app);
        setAction(action);
        setDialogOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setSelectedManufacturer(null);
    };
    const handleConfirm = () => {
        if (action === 'enable') {
            addSetting({
                variables: {
                    setting: {
                        settingType: mode==0 ?settingType:settingType2,
                        settingKey: currentApp.appname,
                        settingValue: currentApp.appname,
                    },
                },
            });
        } else if (action === 'disable') {
            deleteSetting({
                variables: {
                    settingType: mode == 0 ? settingType : settingType2,
                    settingKey: currentApp.appname,
                },
            });
        }
        setMode(null);
        refreshAppCerts().then(() => { enqueueSnackbar('Updated Settings', { variant: 'success' }); });
        setDialogOpen(false);
    };

    const handleCancel = () => {
        setMode(null);
        setDialogOpen(false);
    };

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">Error: {error.message}</Typography>;
    const enabledAppNames = settingData.admin.configuration.enabledAppProviders.map(setting => setting.settingKey);
    const requirePhotoForAppProviderNames = settingData.admin.configuration.requirePhotoForAppProviders.map(setting => setting.settingKey);
    const combinedApps =  filteredData.apps.map(app => ({
        ...app,
        enabled: enabledAppNames && enabledAppNames.includes(app.appname),
       requiresSerialPhoto: requirePhotoForAppProviderNames && requirePhotoForAppProviderNames.includes(app.appname),
    }));
     const currentUserId = UserProfile.getUserId();
    const allowedAdmin =  SAdminList.indexOf(currentUserId) > -1;
   

    return (
        <Container className={classes.container}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="Manufacturers" />
                    <Tab label="VSPs" />
                    <Tab label="App Providers" />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <TextField
                    label="Search Manufacturers"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={search.manufacturers}
                    onChange={(e) => handleSearch(e, 'manufacturers')}
                />
                <Grid container spacing={3}>
                    {filteredData.manufacturers.map((manufacturer, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                            <Card className={classes.card}>
                                <CardContent>
                                    <Typography variant="h6">{manufacturer.name}</Typography>
                                    <Box className={classes.supplierSummary}>
                                        <Typography variant="body2">
                                            Total Suppliers: {manufacturer.suppliers.length}
                                        </Typography>
                                        {manufacturer.suppliers.slice(0, 3).map((supplier, idx) => (
                                            <Typography key={idx} variant="body2">{supplier.name}</Typography>
                                        ))}
                                    </Box>
                                    <Button variant="outlined" color="primary" onClick={() => handleClickOpen(manufacturer)}>
                                        View Details
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <TextField
                    label="Search VSPs"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={search.endpoints}
                    onChange={(e) => handleSearch(e, 'endpoints')}
                />
                <Grid container spacing={3}>
                    {filteredData.endpoints.map((endpoint, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                            <Card className={classes.card}>
                                <CardContent>
                                    <Typography variant="h6">Service Administrator: {endpoint.serviceadministrator}</Typography>
                                    <Typography className={classes.importantLabel}>
                                        Product Verification
                                    </Typography>
                                    <Typography className={classes.importantLabel}>
                                        Install Verification
                                    </Typography>
                                    <Typography className={classes.importantLabel}>
                                        Serial to Model Lookup
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <TextField
                    label="Search App Providers"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={search.apps}
                    onChange={(e) => handleSearch(e, 'apps')}
                />
                <Grid container spacing={3}>
                    <Box bgcolor="#FFF3E0" display="flex" alignItems="center" p={2} borderRadius={4}>
                        <WarningIcon style={{ color: '#FF9800', marginRight: '8px' }} />
                        <Typography variant="h6" style={{ color: '#FF9800', fontWeight: 'bold' }}>
                            Warning: Only authorized users should modify App Provider settings. Changes to SPV access may impact App Provider functionality, and enabling/disabling the barcode serial photo requirement should be as per Contract.
                        </Typography>
                    </Box>
                    {
                        
                        combinedApps.map((app, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                            <Card className={classes.card}>
                                <CardContent>
                                    <Typography variant="h6">App Name: {app.appname}</Typography>
                                    <Typography variant="body2">Service Administrator: {app.serviceadministrator}</Typography>
                                    {settingData.admin.hasConfigAccess && (
                                        <FormControlLabel
                                            control={
                                                    <Switch disabled={!allowedAdmin }
                                                    checked={app.enabled}
                                                    onChange={() => handleToggle(app, app.enabled ? 'disable' : 'enable',0)}
                                                    name="appToggle"
                                                    color="primary" />
                                            }
                                           label="Activate Provider"
                                        />
                                    )}
                                    {settingData.admin.hasConfigAccess && (
                                        <FormControlLabel
                                            control={
                                                    <Switch disabled={!allowedAdmin || !app.enabled }
                                            checked={app.requiresSerialPhoto}
                                            onChange={() => handleToggle(app, app.requiresSerialPhoto ? 'disable' : 'enable',1)}
                                            name="appSerialPhotoToggle"
                                            color="primary"
                                                />
                                         }
                                            label="Make Photo Mandatory"
                                        />
                                        )}
                                        {!settingData.admin.hasConfigAccess && (
                                            <>
                                                <Typography variant="h6">Enabled: {app.enabled ? "Yes" : "no"}</Typography>
                                                <Typography variant="h6">Serial Photo Required: {app.requiresSerialPhoto ? "Yes" : "no"}</Typography>
                                            </>
                                        )}
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </TabPanel>
            {selectedManufacturer && (
                <Dialog open={open} onClose={handleClose} aria-labelledby="supplier-details-dialog">
                    <DialogTitle id="supplier-details-dialog">Supplier Details</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography variant="h6">{selectedManufacturer.name}</Typography>
                            {selectedManufacturer.suppliers.map((supplier, idx) => (
                                <Box key={idx} mt={2}>
                                    <Typography variant="body1">Supplier: {supplier.name}</Typography>
                                    <Typography variant="body2">ID: {supplier.id}</Typography>
                                    <Typography variant="body2">Start Date: {supplier.startdate}</Typography>
                                    <Typography variant="body2">End Date: {supplier.enddate}</Typography>
                                    <Typography variant="body2">Endpoint ID: {supplier.endpointid}</Typography>
                                </Box>
                            ))}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            <Dialog
                open={dialogOpen}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{action === 'enable' ? "Enable App Provider" : "Disable App Provider"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to {action === 'enable' ? "enable" : "disable"} the {mode==0?"app provider":" mandatory photo requirement for app" } {currentApp?.serviceadministrator} - {currentApp?.appname}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
};

export default ReferenceDataPresenter;
