import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from "react";
import { Link } from 'react-router-dom';
import { AppBar, Toolbar } from '@material-ui/core/';
import CssBaseline from '@material-ui/core/CssBaseline';
import logo from '../assets/images/logo.svg'
import clsx from 'clsx';
import {
    Avatar,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemText, IconButton
} from '@material-ui/core';
import Profile from './Profile';

import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';

const drawerWidth = 240;
const styles = theme => ({
    root: {
        display: 'flex',
        height: "100vh",
        [theme.breakpoints.down('sm')]: {
            height: "auto",
        },
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        background: theme.palette.primary.main,
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),

        boxShadow: 'none',
        
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color:theme.palette.common.white
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
        marginTop:64
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    
    hiddenContainer: {
        display: 'flex',
        flex: '1 1 auto'
    },
    bigAvatar: {
        borderRadius: 0,
        width: 142,
        height: "auto",
        paddingLeft: "21px",
        cursor:'pointer'
    },
    
});

const Navigation = ({ classes, onNavigationChange, selectedIndex ,menuState}) => {
    
    const listItems = [
        { to: '/AdminDashboard', primary: 'Dashboard', index: 0 },
        { to: '/Manufacturers', primary: 'Manufacturers', index: 1 },
        { to: '/Suppliers', primary: 'Suppliers', index: 3 },
        { to: '/AdminInstaller', primary: 'Installers', index: 2 },
        { to: '/AdminSerials', primary: 'Serials', index: 4 },
        { to: '/AdminStats', primary: 'Leadership Board', index: 5 },
        { to: '/Logs', primary: 'Logs', index: 6 },
        { to: '/PullRequests', primary: 'Pull Requests', index: 7 },
        { to: '/Miscellaneous', primary: 'Miscellaneous', index: 8 },
        { to: '/CreatePullRequest', primary: 'New PullRequest', index: 9 },
        { to: '/ImportWeeklySupplierList', primary: 'Import Weekly Supplier Data', index: 10 },
        { to: '/ManagePostCodes', primary: 'Manage Postcodes', index: 11 },
        { to: '/Users', primary: 'Users', index: 12 },
        { to: '/reports', primary: 'Reports', index: 13 },
        { to: '/DetailView', primary: 'Detail View', index: 14 },
        { to: '/ReferenceData', primary: 'Reference Data', index: 15 },
        
    ];

    const drawer = (
       
            <List>
                {listItems.map((item) => (
                    <ListItem
                        key={item.index}
                        button
                        component={Link}
                        to={item.to}
                        selected={selectedIndex === item.index}
                        onClick={(event) => onNavigationChange(item.index)}
                        classes={{ selected: classes.listselect, root: classes.listCustom }}
                    >
                        <ListItemText primary={item.primary} classes={{ primary: classes.text }} />
                    </ListItem>
                ))}
            </List>
       
    );

    return (<Drawer anchor="left" variant="persistent" className={classes.drawer} classes={{ paper: classes.drawerPaper }} open={menuState}>
            {drawer}
        </Drawer>

    );
};


const AdminMain = ({ classes, children, location }) => {
    const [header, setHeader] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(calculateHighlightIndex());
    const [menuState, setMenuState] = React.useState(false);
    function onNavigationChange(newIndex) {
        setSelectedIndex(newIndex);
    }
    const handleMenuClick = () => {
        setMenuState(!menuState);
    }

    function updateProfile() {
        setHeader(!header);
    }

    function calculateHighlightIndex() {
        const path = location.pathname;
        const lowercasePath = (path || '').toLowerCase();
        switch (lowercasePath) {
            case '/admindashboard':
                return 0;
            case '/manufacturers':
                return 1;
            case '/admininstaller':
                return 2;
            case '/suppliers':
                return 3;
            case '/adminserials':
                return 4;
            case '/adminserials':
                return 4;
            default:
                return -1;
        }
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={clsx(classes.appBar, {
                [classes.appBarShift]: menuState,
            })} color="default">
                    <Toolbar>
                    <IconButton aria-label="menu" onClick={() => { handleMenuClick(); }}
                        edge="start"
                        className={clsx(classes.menuButton)}
                    >
                            {menuState && (<MenuOpenIcon />)}
                            {!menuState && (<MenuIcon />)}
                    </IconButton>
                    <Avatar alt="logo" src={logo} component={Link} to="/" className={classes.bigAvatar} />
                        <Profile />
                    </Toolbar>
                </AppBar>
               
                    <nav className={classes.drawer}>
                        <Navigation classes={classes}   menuState={menuState} selectedIndex={selectedIndex} onNavigationChange={onNavigationChange} />
                    </nav>
             
                <div className={clsx(classes.content, {
                    [classes.contentShift]: menuState,
                })}>

                <div className={classes.drawerHeader}>
                    {children}
                </div>
                    
                </div>
           
        </div>
    );
};

AdminMain.propTypes = {
    classes: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminMain);

